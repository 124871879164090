/* eslint-disable camelcase */
import React, {Component} from 'react';
import {checkImage, fetchApi, getOrientation, resetOrientation} from "../../utils/methods";
import {toast} from "react-toastify";
import {/*ImageUrl,*/ ImageUrl, /*imageErrorMessage*/} from "../../utils/constants";
import PropTypes from "prop-types";
// import { createCompany } from "../../redux/actions";
import {connect} from "react-redux";
import moment from 'moment';
import RadioButton from "../CustomRadioButton";
import {AddButton, ClientAnniversary, Layout, TitleContainer, ListContainer} from './styles';
import { CustomRadioButton, InputImage, Locationwrapper, MUICalendarWrapper, RemoveImageV2, StyledInputV2, StyledLabel, UploadPhotoContainer, ButtonContainer, SearchDropDown } from '../CreateEvent/style';
import { t } from 'i18next';
import { CalendarIcon, LaunchEventIcon, UploadIcon, closeIcon } from '../../utils/icons';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CommonButton from '../common/CommonButton/CommonButton';
import { Main } from '../AdminTeamCreation/styles';
import { CompanyCreate, GetCompanyCouponCodes, GetCompanyProducts } from '../../redux/constants/apiConstants';
import CommonDropdown from '../common/CommonDropDown/CommonDropdown';
import Select from "react-select";
import { CountryData } from '../../../countryCode';
import { getAllCompanies } from '../../redux/actions/companyDashboard';

const userList = [
  {
    "id": 3,
    "user_tag": "Employee",
    "sendId": "employee"

  },
  {
    "id": 1,
    "user_tag": "Spouse",
    "sendId": "spouse"
  },
  {
    "id": 2,
    "user_tag": "Dependent",
    "sendId": "dependent"

  }
]

class CompanyFeature extends Component{
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      title: '',
      headerImgSrc: '',
      footerImgSrc: '',
      headerImageName: '',
      footerImageName: '',
      Blurb: '',
      recipeCategorieslist: [],
      userCategorieslist: [3],
      addOnlist: [],
      showContent:'',
      locationContent: '',
      departmentContent: '',
      LocationList: [],
      DepartmentList:[],
      directions:[],
      imageFiles:[],
      editedContent: [],
      editSteps:[],
      description:'',
      error: [],
      isLocationDisable: false,
      isDepartmentDisable: false,
      isAddonSelected: false,
      startRewardDate: dayjs(),
      endRewardDate: dayjs().add(1, 'year'),
      employeeCount: "",
      clientAnniversaryDate: null,
      password: '',
      show: false,
      validDate: null,
      isDisabledButton: false,
      featureList:[],
      couponCodes:[],
      selectedCoupon:"",
      country:"",
      state:"",
      city:"",
      address1:"",
      address2:"",
      postalCode:"",
      billingCycle:"m",
      email:"",
      companyStartDate:dayjs(),
      companyEndDate:dayjs().add(1, 'month'),
    };
  }

  componentDidMount(){
    window.console.log("mount")
    let date = new Date(this.state.endRewardDate), y = date.getFullYear(), m = date.getMonth();
    let firstDay;
    if(m===11){
      firstDay = new Date(y+1, 0, 1);
    }else{
      firstDay = new Date(y, m+1, 1);
    }
    firstDay = moment(firstDay).format('MMMM Do,YYYY');
    this.setState({
      clientAnniversaryDate: firstDay,
    })
    this.getAllCompanyProducts();
    this.getAllCouponCodes();
  }

  getAllCompanyProducts = async()=>{
    try {
      const res = await fetchApi(GetCompanyProducts , "GET");
      window.console.log("res",res)
      if (res.status === "success") {

        if(res.message){
          toast.error(res.message)
        }else{
          this.setState({featureList: res?.data})
        }
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      window.console.log(error);
    }
  }
  

  getAllCouponCodes = async()=>{
    try {
      const res = await fetchApi(GetCompanyCouponCodes , "GET");
      window.console.log("res",res)
      if (res.status === "success") {

        if(res.message){
          toast.error(res.message)
        }else{
          let couponCodes = res?.data;
          couponCodes.unshift({code:null, coupon_name:"None"});
          this.setState({couponCodes: couponCodes})
        }
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      window.console.log(error);
    }
  }

  onChangeInput = (e) => {
    if(e.target.value !== ' ') {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };


  changeDate = (e, current) => {
    if(current === 'date'){
    
      this.setState({
        startRewardDate : dayjs(e),
        openDateTimePicker: '',
        endRewardDate: dayjs(e).add(1, 'year')
      })
    }else if(current === 'enddate'){
      let date = e;
      let firstDay, m=date.getMonth(), y = date.getFullYear();
      if(m===11){
        firstDay = new Date(y+1, 0, 1)
      }
      else{
        firstDay = new Date(y, m+1, 1)
      }
      firstDay = dayjs(firstDay).format('MMMM Do,YYYY');
      this.setState({
        endRewardDate : dayjs(e),
        openDateTimePicker: '',
        clientAnniversaryDate: firstDay
      })
    }
  };

  changeCompanyDate = (e, current) => {
    const {billingCycle}=this.state;

    if(current === 'date'){
      this.setState({
        companyStartDate: dayjs(e),
        companyEndDate: billingCycle=== "m"?  dayjs(e).add(1, 'month') : dayjs(e).add(1, 'year')
      })
    }else if(current === 'enddate'){
      this.setState({
        companyEndDate: dayjs(e),
        endDate: dayjs(e),
      })
    }
  };

  onChange = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if (e.target.files[0]) {
      if (checkImage(fileArr)) {
        if ((file?.size / 1000000) <= 20) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document.getElementById('recipe-upload-file').value.split("\\");
          reader.onloadend = () => {
            this.setState({
              headerImgSrc: reader.result,
              headerImageName: array[array.length - 1],
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  headerImgSrc: baseImage
                });
              });
            });
          };
        } else {
          toast.error('Please select image file less than 20MB');
          document.getElementById('recipe-upload-file').value = '';
        }
      } else {
        toast.error("Please select valid format (.jpeg, .png)");
        document.getElementById('recipe-upload-file').value = '';
      }
    }
  };
  removePhoto = () => {
    // document.getElementById('recipe-upload-file').value = '';
    this.setState({
      headerImgSrc: '',
      headerImageName: '',
    });
  };



  onChangeCategory = (id) => {
    const { recipeCategorieslist } = this.state;
    if(recipeCategorieslist.includes(id) && id!==11 && id!==12 && id!==13 && id!==14 && id!==15) {
      let index = recipeCategorieslist.findIndex((item) => item === id);
      if(index > -1) {
        recipeCategorieslist.splice(index, 1);
      }
    } else {
      recipeCategorieslist.push(id);
    }
    this.setState({
      recipeCategorieslist: recipeCategorieslist
    })
  };

  onChangeUserCategory = (id) => {
    const { userCategorieslist } = this.state;
    if(userCategorieslist.includes(id) && id!==3) {
      let index = userCategorieslist.findIndex((item) => item === id);
      if(index > -1) {
        userCategorieslist.splice(index, 1);
      }
    } else {
      userCategorieslist.push(id);
    }
    this.setState({
      userCategorieslist: userCategorieslist
    })
  };


  onChangeDepartment = (e) => {
    // e.preventDefault();
    window.console.log("onChangeDepartment");
    if(e.target.value !== ' ') {
      this.setState({departmentContent: e.target.value})
    }
  };

  onChangeDepartmentName = (e,index)=>{
    const {DepartmentList} = this.state;
    let departments = [...DepartmentList]
    departments[index] = e.target.value;
    this.setState({DepartmentList: departments});
  }
  onChangeLocation = (e) => {
    if(e.target.value !== ' ') {
      this.setState({locationContent: e.target.value})
    }
  };

  onChangeLocationName = (e,index)=>{
    const {LocationList} = this.state;
    let locations = [...LocationList]
    locations[index] = e.target.value;
    this.setState({LocationList: locations});
  }
  storeLocation = () => {
    const{locationContent, LocationList} = this.state;
    let arr = [];
    arr = [...LocationList];
    arr.push(locationContent);
    this.setState({LocationList: arr, locationContent: ''});
  };
  removeLocation = (index) => {
    const{LocationList} = this.state;
    LocationList.splice(index, 1);
    this.setState({LocationList: LocationList});
  };

  storeDepartment = () => {
    const{departmentContent, DepartmentList} = this.state;
    let arr = [];
    arr = [...DepartmentList];
    arr.push(departmentContent);
    this.setState({DepartmentList: arr, departmentContent: ''});
  };
  removeDepartment = (index) => {
    const{DepartmentList} = this.state;
    DepartmentList.splice(index, 1);
    this.setState({DepartmentList: DepartmentList});
  };


  onChangeCount = (e) => {
    e.preventDefault();
    this.setState({
      employeeCount: e.target.value
    })
  }

  selectMany = (id) => {
    const { recipeCategorieslist } = this.state;
    if(id===1){
      recipeCategorieslist.push(19);
      recipeCategorieslist.push(15);
      recipeCategorieslist.push(20);
      // recipeCategorieslist.push(18);
      recipeCategorieslist.push(17);
  
    
      if(recipeCategorieslist.includes(16)){
        let index = recipeCategorieslist.findIndex((item) => item === 16);
        if(index > -1) {
          recipeCategorieslist.splice(index, 1);
        }
      }
      // if(recipeCategorieslist.includes(17)){
      //   let index = recipeCategorieslist.findIndex((item) => item === 17);
      //   if(index > -1) {
      //     recipeCategorieslist.splice(index, 1);
      //   }
      // }
      if(recipeCategorieslist.includes(18)){
        let index = recipeCategorieslist.findIndex((item) => item === 17);
        if(index > -1) {
          recipeCategorieslist.splice(index, 1);
        }
      }
      if(recipeCategorieslist.includes(21)){
        let index = recipeCategorieslist.findIndex((item) => item === 21);
        if(index > -1) {
          recipeCategorieslist.splice(index, 1);
        }
      }
      if(recipeCategorieslist.includes(22)){
        let index = recipeCategorieslist.findIndex((item) => item === 22);
        if(index > -1) {
          recipeCategorieslist.splice(index, 1);
        }
      }
      if(recipeCategorieslist.includes(23)){
        let index = recipeCategorieslist.findIndex((item) => item === 23);
        if(index > -1) {
          recipeCategorieslist.splice(index, 1);
        }
      }
    }else{
      recipeCategorieslist.push(16);
      recipeCategorieslist.push(17);
      recipeCategorieslist.push(21);
      recipeCategorieslist.push(22);
      recipeCategorieslist.push(23);
      recipeCategorieslist.push(19);
      recipeCategorieslist.push(15);
      recipeCategorieslist.push(20);
      recipeCategorieslist.push(18);

    }
    
    this.setState({
      recipeCategorieslist: recipeCategorieslist
    })
  }

  disableDepartment = () => {
    const { DepartmentList } = this.state;
    DepartmentList.length = 0;
    this.setState((prev)=>({
      isDepartmentDisable: !prev.isDepartmentDisable,
      DepartmentList: DepartmentList,
      departmentContent: ''
    }))
  }

  disableLocation = () => {
    window.console.log("disableLocation");
    const { LocationList } = this.state;
    LocationList.length = 0;
    this.setState((prev)=>({
      isLocationDisable: !prev.isLocationDisable,
      LocationList: LocationList,
      locationContent: ''
    }))
  }

  selectAllAddons = () => {
    const { isAddonSelected, addOnlist } = this.state;
    if( isAddonSelected===false ){
      addOnlist.push(1);
      addOnlist.push(2);
      addOnlist.push(3);
      addOnlist.push(4);
      addOnlist.push(5);
      this.setState({
        addOnlist: addOnlist,
        isAddonSelected: true
      })
    }
    else{
      this.setState({
        addOnlist: [],
        isAddonSelected: false
      })
    }
  }

  submitCompanyFeature = () => {
    const { password, recipeCategorieslist, userCategorieslist, title, headerImgSrc, employeeCount, startRewardDate, endRewardDate, LocationList, DepartmentList, email,address1, city, state, country, postalCode, selectedCoupon, billingCycle, companyStartDate,companyEndDate,address2} = this.state;
    // const { createCompanyFeature } = this.props;
    this.setState({
      isDisabledButton : true
    })
    let obj = {
      company_name: title,
      company_password: password,
      company_header_logo: headerImgSrc,
      eligible_employee_count: Number(employeeCount),
      reward_start_date: dayjs(startRewardDate).format("YYYY-MM-DD"),
      reward_end_date: dayjs(endRewardDate).format("YYYY-MM-DD"),
      company_locations : LocationList,
      company_departments: DepartmentList,
      product_ids:recipeCategorieslist,
      roles:{
        spouse: userCategorieslist.includes(1) ? 1 : 0,
        dependent: userCategorieslist.includes(2) ? 1 : 0
      },
      contact_email:email,
      address_line1:address1,
      address_line2:address2,
      city:city,
      state:state,
      country:country?.value,
      postal_code:Number(postalCode),
      coupon_code:selectedCoupon?.code ? selectedCoupon?.code: "",
      billing_cycle:billingCycle,
      start_date:dayjs(companyStartDate).format("YYYY-MM-DD"),
      end_date:dayjs(companyEndDate).format("YYYY-MM-DD"),
      
    }
    // createCompanyFeature(obj);
    this.createNewCompany(obj)
  }

  createNewCompany = async(payload)=>{
    try {
      const res = await fetchApi(CompanyCreate , "POST",payload);
      window.console.log("res",res)
      if(res.data.message){
        toast.error(res.data.message);
        this.setState({
          isDisabledButton : false
        })
      }else{
        toast.success(res.data[0]);
        this.setState({
          isDisabledButton : false
        });
        const year = new Date().getFullYear();
        this.props.fetchAllCompanies(year);
        this.props.history.push("/company/dashboard");
      }
    } catch (error) {
      window.console.log(error);
    }


  }


  togglePassword = () => {
    const password = document.querySelector('#password');
    const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);
    this.setState((prev)=>({
      display: !prev.display
    }))
  }

  handlePassword = (e) => {
    e.preventDefault();
    if(e.target.value !== ' '){
      this.setState({
        password: e.target.value
      })
    }
    if(e.target.value.length<8){
      this.setState({
        show: true
      })
    }else{
      this.setState({
        show: false
      })
    }
  }

  onSelectCoupon = (name, /*value, key*/) => {
    window.console.log("name",name)
    this.setState({
      selectedCoupon:name
    });
  };
  onSelectCountry = (value)=>{
    this.setState({country:value})
  }

  setBillingCycle =(billingCycle)=>{
    const{companyStartDate}=this.state;
    this.setState({billingCycle:billingCycle,companyEndDate: billingCycle=== "m"?  dayjs(companyStartDate).add(1, 'month') : dayjs(companyStartDate).add(1, 'year')})
  }

  DropdownIndicator = () => (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 10C12 10.3585 11.8559 10.7169 11.5684 10.9901L2.51663 19.5897C1.94083 20.1368 1.00725 20.1368 0.431681 19.5897C-0.143894 19.0429 -0.143894 18.1561 0.431681 17.609L8.44122 10L0.43196 2.39098C-0.143614 1.84394 -0.143614 0.957264 0.43196 0.410484C1.00753 -0.136826 1.94111 -0.136826 2.51691 0.410484L11.5687 9.00992C11.8563 9.28333 12 9.64172 12 10Z"
        fill="#007AB1"
      />
    </svg>
  );

  render() {
    const{show, password, display, title, locationContent, departmentContent, recipeCategorieslist, DepartmentList,
      LocationList, userCategorieslist, isLocationDisable, isDepartmentDisable,
      startRewardDate, endRewardDate, clientAnniversaryDate, employeeCount, /*headerImageName,*/ isDisabledButton, headerImgSrc,featureList,selectedCoupon, couponCodes,country, state, city, address1, address2, postalCode, email, billingCycle, companyStartDate, companyEndDate } = this.state;

    const customStyles = {
      control: (base, state) => ({
        ...base,
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
          borderColor: state.isFocused ? "hsl(0,0%,70%)" : "hsl(0,0%,70%)",
        },
      }),
    };

    return (
      <>
        <Layout>
          <div className='create-company'><span className='global-title'>Create a new company</span></div>
          <div className='main-container'>
            <div className='form-container'>
              <TitleContainer padding={"0px"} style={{paddingBottom:"16px", float:"unset"}}>
                <label className='global-label' style={{marginBottom:"16px"}}>{t("Company name")}{<span className='global-star'>*</span>}</label>
                <StyledInputV2
                  placeholder={t("Enter company name here...")}
                  name="title"
                  onChange={(e)=>this.onChangeInput(e)}
                  value={title}
                  maxLength="100"
                  style={{background:"white"}}
                  pColor={"#649bb3"}
                  color="#005c87"
                  id='title'
                />
              </TitleContainer>

              <StyledLabel color={"#005c87"} style={{marginTop:"20px"}}>{t("Add company header logo")}{<span>*</span>}</StyledLabel>
              <UploadPhotoContainer>
                <div className="innerDivImageContainer">
                  {headerImgSrc ? (
                    <RemoveImageV2>
                      <img alt="profilre-img" src={headerImgSrc}></img>
                      <div className="main-div" onClick={()=>this.removePhoto()}>
                        <div className="icon-div" ><div className="sub-icon-div">{closeIcon("white","8","8")}</div></div>
                      </div>
                    </RemoveImageV2>
                  ) : (
                    <div className="uploadImage">
   
                      <InputImage
                        className="edit-profile-pic "
                        imageReload={1} 
                        style={{background:"unset",color:"#005c87"}}
                      >
                        <div className="middle-section">
                          <div>{UploadIcon()}</div>
                          <div className="title">{t("Upload file")}</div>
                          <div className="sub-title">{t("(.jpeg, .png)")}</div>
                          
                        </div>
                        <input
                          id="recipe-upload-file"
                          type="file"
                          name="recipeImage"
                          accept=".jpeg, .png, .jpg"
                          multiple={false}
                          onChange={(e) => this.onChange(e)}
                          onClick={(e) =>
                            e.target.files[0] && this.onChange(e)
                          }
                        />
                      </InputImage>
                    </div>
                  )}
                </div>
              </UploadPhotoContainer>
   
  

              <TitleContainer padding={"0px"} style={{paddingBottom:"16px", float:"unset"}}>
                <StyledLabel color={"#005c87"}>{t("Eligible users")}{<span>*</span>}</StyledLabel>
                <StyledInputV2
                  placeholder={t("Enter number of eligible users here...")}
                  // name="Blurb"
                  onChange={(e)=>this.onChangeCount(e)}
                  value={employeeCount}
                  type='number'
                  min={"0"}
                  style={{background:"white"}}
                  pColor={"#649bb3"}
                  color="#005c87"
                  name='employeeCount'
                  id='employeeCount'
                />
              </TitleContainer>

              <StyledLabel color={"#005c87"} style={{marginTop:"20px", float:"unset"}}>{t("Points & rewards start date")}{<span>*</span>}</StyledLabel>
   
              <div className="calendar">
   
                <LocalizationProvider dateAdapter={AdapterDayjs} sx={{width:"100%"}}>
                  <MUICalendarWrapper>
     
                    <DatePicker
                      label="Select start date"
                      sx={{width:"50%",color:"#005C87",borderColor:"#005C87",marginRight:"40px"}}
                      onChange={(value)=>{this.changeDate(value.$d, "date")}}
                      value={startRewardDate}
                      // disabled={launch === 0}
                      maxDate={endRewardDate}
                      slots={{openPickerIcon:CalendarIcon}}
                      disablePast={true}
                    />
                    <DatePicker
                      label="Select end date"
                      sx={{width:"50%"}}
                      onChange={(value)=>{this.changeDate(value.$d, "enddate")}}
                      value={endRewardDate && endRewardDate}
                      minDate={startRewardDate}
                      slots={{openPickerIcon:CalendarIcon}}
                      disablePast={true}
                    />
                  </MUICalendarWrapper>
                </LocalizationProvider>
              </div>

              <ClientAnniversary style={{float:"unset", padding:"20px 0px"}}>
                <div>Client anniversary</div>
                <div>{clientAnniversaryDate}</div>
                <div>This is the day the client points and rewards will reset on the platform for all users.</div>
              </ClientAnniversary>
              <TitleContainer style={{paddingBottom:"16px", float:"unset"}}>
                <div className="outerDiv">
                  <div>
                    {/* <FieldTitleIngredients>Locations:</FieldTitleIngredients> */}
                    <StyledLabel color={"#005c87"}>{t("Locations")}{<span>*</span>}</StyledLabel>
                  </div>
                  <div style={{color:"#005c87",display:"flex"}}>
                    <CustomRadioButton
                      onClick={() =>
                        this.disableLocation()
                      }
                      style={{color:"#005c87",border:"1px solid #669db7"}}
                      background={"#85c0ea"}
                    >
                      {" "}
                      {isLocationDisable && (<div></div>)}{" "}
                    </CustomRadioButton>{" "}
                    <div>{t("No locations")}</div>
                  </div>
                </div>

                <div style={{display:"flex",alignItems:"center"}}>
                  <StyledInputV2
                    placeholder={t("Enter locations here...")}
                    name="LocationList"
                    id='location'
                    onChange={(e)=>this.onChangeLocation(e)}
                    value={locationContent}
                    type='text'
                    style={{background:"white",width:"80%"}}
                    pColor={"#649bb3"}
                    color="#005c87"
                    disabled={isLocationDisable}
                  />

                  { locationContent.replace(/^\s\s*/, '').replace(/\s\s*$/, '') && <AddButton onClick={this.storeLocation}>{"Add Location"}</AddButton>}
                </div>
                {LocationList.map((data,index) => (
                  <ListContainer key={"location" + index}>
                    <div className='dept-name'>
                      <StyledInputV2
                        placeholder={t("Enter location here...")}
                        name="departmentContent"
                        id='company-dpeartment'
                        value={data}
                        onChange={(e)=>this.onChangeLocationName(e, index)}
                        type='text'
                        style={{background:"white"}}
                        pColor={"#649bb3"}
                        color="#005c87"
                        // disabled={isDepartmentDisable}
                      />
                    </div>

                    <div className='closeIcon' onClick={() => this.removeLocation(index)}>
                      {closeIcon("#005c87","20","20")}
                    </div>
                  </ListContainer>
                ))}
              </TitleContainer>
              <TitleContainer style={{paddingBottom:"20px", float:"unset"}}>
                <div className="outerDiv">
                  <div>
                    <StyledLabel color={"#005c87"}>{t("Departments")}{<span>*</span>}</StyledLabel>
                  </div>
                  <div style={{color:"#005c87",display:"flex"}}>
                    <CustomRadioButton
                      onClick={() =>
                        this.disableDepartment()
                      }
                      style={{color:"#005c87",border:"1px solid #669db7"}}
                      background={"#85c0ea"}
                    >
                      {" "}
                      {isDepartmentDisable && (<div></div>)}{" "}
                    </CustomRadioButton>{" "}
                    <div>{t("No Departments")}</div>
                  </div>
                </div>

                <div style={{display:"flex",alignItems:"center"}}>
                  <StyledInputV2
                    placeholder={t("Enter department here...")}
                    name="departmentContent"
                    id='company-dpeartment'
                    value={departmentContent}
                    onChange={(e)=>this.onChangeDepartment(e)}
                    type='text'
                    style={{background:"white", width:"80%"}}
                    pColor={"#649bb3"}
                    color="#005c87"
                    disabled={isDepartmentDisable}
                  />
                  
                  { departmentContent.replace(/^\s\s*/, '').replace(/\s\s*$/, '') && <AddButton onClick={this.storeDepartment}>{"Add Department"}</AddButton>}
                </div>
                {DepartmentList.map((data,index) => (
                  <ListContainer key={"depatment" + index}>
                    <div className='dept-name'>
                      <StyledInputV2
                        placeholder={t("Enter department here...")}
                        name="departmentContent"
                        id='company-dpeartment'
                        value={data}
                        onChange={(e)=>this.onChangeDepartmentName(e, index)}
                        type='text'
                        style={{background:"white"}}
                        pColor={"#649bb3"}
                        color="#005c87"
                        // disabled={isDepartmentDisable}
                      />
                    </div>

                    <div className='closeIcon' onClick={() => this.removeDepartment(index)}>
                      {closeIcon("#005c87","20","20")}
                    </div>
                  </ListContainer>
                ))}
              </TitleContainer>
   
              <TitleContainer style={{padding:"16px 0px", float:"unset"}}>
                <div className="outerDiv">
                  <div>
                    <label className='global-label'>{t("Select billing cycle")}{<span className='global-star'>*</span>}</label>
                  </div>
                  <div style={{color:"#005c87",display:"flex"}}>
                    <RadioButton id="1" name = "gender" isChecked={billingCycle === "m"} handler={()=>this.setBillingCycle("m")} value={'m'} label={'Monthly'} createSurvey={true}/>
                    <RadioButton id="2" name = "gender" isChecked={billingCycle === "y"} handler={()=>this.setBillingCycle("y")} value ={'y'} label={'Yearly'} createSurvey={true}/>
                  </div>
                </div>
              </TitleContainer>
   
              <TitleContainer style={{padding:"16px 0px", float:"unset"}}>
                <div className="outerDiv" style={{marginBottom:"16px"}}>
                  <div>
                    <label className='global-label'>Main platform features{<span className='global-star'>*</span>}<br/><span className='global-sub-label'>Please select the platform features you like to turn on</span></label>
                  </div>
                </div>
   
                <Locationwrapper  background="white" style={{zIndex:"10",position:"relative"}}>
                  {featureList && featureList?.length > 0
                    ? featureList.map((product, index) => (
                      <div className="checkBoxWidth" key={index}>
                        <span style={{color:"#005c87"}}>
                          <CustomRadioButton
                            onClick={() => this.onChangeCategory(product['id'])}
                            background="#afcbd3"
                            style={{border:"1px solid #649bb3"}}
                          >
                            {" "}
                            {recipeCategorieslist.includes(product['id']) && (
                              // <div></div>
                              <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                            )}{" "}
                          </CustomRadioButton>
                          {product['product_name']}
                        </span>
                      </div>
                    ))
                    : null}
                </Locationwrapper>
              </TitleContainer>
   
              {/* Features */}
   


              {/* User List */}
              <TitleContainer style={{padding:"16px 0px", float:"unset"}}>
                <div className="outerDiv" style={{marginBottom:"16px"}}>
                  <div>
                    <label className='global-label'>User and roles{<span className='global-star'>*</span>}<br/><span className='global-sub-label'>Please select the users you like to have on the platform</span></label>
                  </div>
                </div>

                <Locationwrapper  background="white" style={{zIndex:"10",position:"relative"}}>
                  {userList && userList?.length > 0
                    ? userList.map((tag, index) => (
                      <div className="checkBoxWidth" key={index}>
                        <span style={{color:"#005c87"}}>
                          <CustomRadioButton
                            onClick={() => this.onChangeUserCategory(tag['id'])}
                            background="#afcbd3"
                            style={{border:"1px solid #649bb3"}}
                          >
                            {" "}
                            {userCategorieslist.includes(tag['id']) && (
                              // <div></div>
                              <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                            )}{" "}
                          </CustomRadioButton>
                          {tag['user_tag']}
                        </span>
                      </div>
                    ))
                    : null}
                </Locationwrapper>
              </TitleContainer>

              {/* Coupon code */}
              <div style={{borderBottom:"1px solid rgba(0,92,135,0.1)",display:"flex"}}>

                <CommonDropdown
                  tileContainerStyle={{ width: "100%",padding:"20px 0px 20px 0px" }}
                  dropdownStyle={{ top: "90px" }}
                  labelText={t("Select coupon code")}
                  // isRequired={true}
                  dropdownOpen={this.state.so}
                  placeholder={t("Select coupon code")}
                  title={
                    selectedCoupon?.coupon_name
                  }
                  id="dropdown-recurring"
                  onClick={() => this.setState({ so: !this.state.so })}
                  data={couponCodes}
                  onSelect={this.onSelectCoupon}
                  active={selectedCoupon}
                  onSelectParameter={["selectedCoupon", "coupon_name", "key"]}
                  // valueString={"Points"}
                  itemValue={true}
                  valueIndex={1}
                  labelMargin={"0px"}
                />
              </div>
              

              {/* Country */}

              <div style={{borderBottom:"1px solid rgba(0,92,135,0.1)", padding:"16px 0px"}}>
                <label className='global-label' style={{marginBottom:"16px"}}>{t("Select country")}{<span className='global-star'>*</span>}</label>
                <SearchDropDown
                  // transform={menuIsOpen ? "rotate(90deg)" : "0"}
                  // pcolor={time_zone ? "#005c87" : "#005c87"}
                  // pfont={time_zone ? "rubik-medium" : "rubik"}
                  style={{ height: "50px", width: '100%'}}
                  aria-autocomplete="none"
                  autoComplete='off'
                  indicatorWidth={"20px"}
                  // disabled={iseventUrl}
                  
                >
                  <Select
                    defaultValue={
                      country ? country.label : t("Select country")
                    }
                    options={CountryData}
                    onChange={(option) =>
                      this.onSelectCountry(option)
                    }
                    classNamePrefix={"menu-item"}
                    styles={customStyles}
                    components={{ DropdownIndicator:  this.DropdownIndicator }}
                    onMenuOpen={this.toggleMenu}
                    onMenuClose={this.toggleMenu}
                    placeholder={
                      country ? country.label : t("Select country")
                    }
                    value={country}
                    // isDisabled={iseventUrl}
                  />
                </SearchDropDown>
              </div>

              <TitleContainer padding={"0px"} style={{paddingBottom:"16px", float:"unset"}}>
                <StyledLabel color={"#005c87"}>{t("State")}{<span>*</span>}</StyledLabel>
                <StyledInputV2
                  placeholder={t("Enter state name here...")}
                  name="state"
                  onChange={(e)=>this.onChangeInput(e)}
                  value={state}
                  maxLength="100"
                  style={{background:"white"}}
                  pColor={"#649bb3"}
                  color="#005c87"
                  id='state'
                />
              </TitleContainer>

              <TitleContainer padding={"0px"} style={{paddingBottom:"16px", float:"unset"}}>
                <StyledLabel color={"#005c87"}>{t("City Name")}{<span>*</span>}</StyledLabel>
                <StyledInputV2
                  placeholder={t("Enter city name here...")}
                  name="city"
                  onChange={(e)=>this.onChangeInput(e)}
                  value={city}
                  maxLength="100"
                  style={{background:"white"}}
                  pColor={"#649bb3"}
                  color="#005c87"
                  id='city'
                />
              </TitleContainer>

              <TitleContainer padding={"0px"} style={{paddingBottom:"16px", float:"unset"}}>
                <StyledLabel color={"#005c87"}>{t("Address line1")}{<span>*</span>}</StyledLabel>
                <StyledInputV2
                  placeholder={t("Enter address line1 here...")}
                  name="address1"
                  onChange={(e)=>this.onChangeInput(e)}
                  value={address1}
                  maxLength="100"
                  style={{background:"white"}}
                  pColor={"#649bb3"}
                  color="#005c87"
                  id='address1'
                />
              </TitleContainer>

              <TitleContainer padding={"0px"} style={{paddingBottom:"16px", float:"unset"}}>
                <StyledLabel color={"#005c87"}>{t("Address line2")}</StyledLabel>
                <StyledInputV2
                  placeholder={t("Enter address line2 here...")}
                  name="address2"
                  onChange={(e)=>this.onChangeInput(e)}
                  value={address2}
                  maxLength="100"
                  style={{background:"white"}}
                  pColor={"#649bb3"}
                  color="#005c87"
                  id='address2'
                />
              </TitleContainer>

              <TitleContainer padding={"0px"} style={{paddingBottom:"16px", float:"unset"}}>
                <StyledLabel color={"#005c87"}>{t("Postal Code")}{<span>*</span>}</StyledLabel>
                <StyledInputV2
                  placeholder={t("Enter postal code here...")}
                  name="postalCode"
                  onChange={(e)=>this.setState({[e.target.name]:e.target.value})}
                  value={postalCode}
                  type='number'
                  style={{background:"white"}}
                  pColor={"#649bb3"}
                  color="#005c87"
                  id='employeeCount'
                />
              </TitleContainer>

              <StyledLabel color={"#005c87"} style={{marginTop:"20px", float:"unset"}}>{t("Subscription date")}{<span>*</span>}</StyledLabel>
   
              <div className="calendar">
            
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MUICalendarWrapper>
            
                    <DatePicker
                      label="Select start date"
                      sx={{width:"50%",color:"#005C87",borderColor:"#005C87",marginRight:"40px"}}
                      onChange={(value)=>{this.changeCompanyDate(value.$d, "date")}}
                      value={companyStartDate}
                      // disabled={launch === 0}
                      maxDate={companyEndDate}
                      slots={{openPickerIcon:CalendarIcon}}
                      disablePast={true}
                    />
                    <DatePicker
                      label="Select end date"
                      sx={{width:"50%"}}
                      onChange={(value)=>{this.changeCompanyDate(value.$d, "enddate")}}
                      value={companyEndDate && companyEndDate}
                      minDate={companyStartDate}
                      slots={{openPickerIcon:CalendarIcon}}
                      disablePast={true}
                    />
                  </MUICalendarWrapper>
                </LocalizationProvider>
              </div>

              <TitleContainer padding={"0px"} style={{paddingBottom:"16px", float:"unset"}}>
                <StyledLabel color={"#005c87"}>{t("Contact email (All the invoices will be sent to this mail)")}{<span>*</span>}</StyledLabel>
                <StyledInputV2
                  placeholder={t("Enter email here...")}
                  name="email"
                  onChange={(e)=>this.onChangeInput(e)}
                  value={email}
                  maxLength="100"
                  style={{background:"white"}}
                  pColor={"#649bb3"}
                  color="#005c87"
                  id='email'
                />
              </TitleContainer>


              {/* company password */}
   
              <TitleContainer padding={"0px"} style={{paddingBottom:"16px", float:"unset"}}>
                <StyledLabel color={"#005c87"} style={{ float:"unset"}}>{t("Company password")}{<span>*</span>}</StyledLabel>
                <div style={{position:"relative"}}>
                  <StyledInputV2
                    placeholder={t("Enter company password here...")}
                    // name="Blurb"
                    onChange={(e)=>this.handlePassword(e)}
                    value={password}
                    type='password'
                    style={{background:"white"}}
                    pColor={"#649bb3"}
                    color="#005c87"

                    name="password" 
                    id="password"
                    aria-autocomplete="none"
                    autoComplete='off'
                    onFocus={(event) => { event.target.setAttribute('autocomplete', 'none'); }}
                  />
                  {
                    display ? <i style={{cursor: "pointer",color:"#005C87", position:"absolute",top:"35%",right:"15px"}} className="far fa-eye-slash eye-icon" onClick={this.togglePassword} /> : <i style={{cursor: "pointer",color:"#005C87", position:"absolute",top:"35%",right:"15px"}} className={`fas fa-eye eye-icon`} onClick={this.togglePassword} />
                  }
                </div>
                {
                  show ? <div className="pass global-label" style={{marginTop:"16px"}}>*Minimum 8 characters required</div> : null
                }
                
              </TitleContainer>
   
            </div>
          </div>
        </Layout>

        <ButtonContainer>
          <Main>
            <CommonButton
              btnType={"squareIcon"}
              disabled={!title || recipeCategorieslist.length <= 0 || !employeeCount || userCategorieslist<=0 || !password  || password.length<8 || isDisabledButton || !headerImgSrc || !email || !city || !address1 || !city || !postalCode || !country || !state}
              styles={{color:"#007AB1", marginAuto:'auto',width:"auto"}}
              onClick={this.submitCompanyFeature}
              title={t("Create company")}
              nextIcon={LaunchEventIcon()}
            />
          </Main>
        </ButtonContainer>
      </>
    );
  }
}
CompanyFeature.propTypes = {
  history: PropTypes.object,
  createCompanyFeature: PropTypes.func,
  editCompany: PropTypes.func,
  fetchAllCompanies: PropTypes.func,
};
// const mapStateToProps = (state) => ({
//   recipeLoading: state.education.recipeLoading
// });
const mapDispatchToProps = (dispatch) => ({
  fetchAllCompanies: (year) => dispatch(getAllCompanies(year)),
});
export default connect(null, mapDispatchToProps)(CompanyFeature);
